import styled from 'styled-components';

export const MyRadioGroupContainer = styled.div`
`;

export const MyRadioContainer = styled.div`
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;
