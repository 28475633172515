import React from 'react';

import { WrapperContainer } from './style';

function WrapWithContainer(WrapperComponent) {
  const ElementWrapper = ({ maxWidth, width, className, ...otherProps }) => (
      <WrapperContainer maxWidth={maxWidth} width={width} className={className}>
        <WrapperComponent {...otherProps} />
      </WrapperContainer>
    );

  return ElementWrapper;
};

export default WrapWithContainer;
