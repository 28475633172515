import React from 'react';
import { MyDescriptionContainer } from './style';
import WrapWithContainer from '../CommonWrapper';

const MyDescription = ({ children, ...otherProps }) => (
  <MyDescriptionContainer {...otherProps}>
    {children}
  </MyDescriptionContainer>
);

export default WrapWithContainer(MyDescription);
