import styled from 'styled-components';

export const MyCheckboxContainer = styled.div`
  font-size: 12px;
  padding: 1px 0 2px;

  .MuiFormControl-root {
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }

  .MuiFormHelperText-root {
    margin-top: 0;
  }
`;
