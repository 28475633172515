/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';

const base_url = `${process.env.BACKEND_API_BASE_URL}/payment-portal-payment.php`;

export const paymentService = {
  create_payment_account: async (data) => axios.get(base_url, {
    params: {
      method: 'create_payment_account',
      ...data,
      token: getLoginUser().token,
    },
  }),
  update_payment_account: async (data) => axios.get(base_url, {
    params: {
      method: 'update_payment_account',
      ...data,
      token: getLoginUser().token,
    },
  }),
  get_propay_account_information: async () => axios.get(base_url, {
    params: {
      method: 'get_propay_account_information',
      token: getLoginUser().token,
    },
  }),
  get_account_information: async () => axios.get(base_url, {
    params: {
      method: 'get_account_information',
      token: getLoginUser().token,
    },
  }),
  get_transaction_history: async () => axios.get(base_url, {
    params: {
      method: 'get_transaction_history',
      token: getLoginUser().token,
    },
  }),
  refund_transaction: async (account_num, trans_num) => axios.get(base_url, {
    params: {
      method: 'refund_transaction',
      account_num,
      trans_num,
      token: getLoginUser().token,
    },
  }),
  get_flash_card: async () => axios.get(base_url, {
    params: {
      method: 'get_flash_card',
      token: getLoginUser().token,
    },
  }),
  add_flash_card: async (data) => axios.get(base_url, {
    params: {
      method: 'add_flash_card',
      ...data,
      token: getLoginUser().token,
    },
  }),
  flash_funds: async (data) => axios.get(base_url, {
    params: {
      method: 'flash_funds',
      ...data,
      token: getLoginUser().token,
    },
  }),
};  
