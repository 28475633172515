import styled from 'styled-components';
import { Table } from '@material-ui/core';

export const MyTableContainer = styled(Table)`
  padding: 4px 0 0 0;
  margin: 0;

  thead th, td {
    font-size: 13px;
    vertical-align: middle;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  td {
    padding: 0.2rem;
  }

  .MuiButton-root {
    min-width: 0;
    border: 1px solid transparent;
  }

  thead th.sortable {
    cursor: pointer;
  }
`;
