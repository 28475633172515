import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { notNull } from 'utils/text';
import WrapWithContainer from '../CommonWrapper';
import { MyDatePickerContainer } from './style';

const moment = require('moment-timezone');

const MyDatePicker = ({ id, label = null, placeholder = 'Input', value, values, onChange = () => {}, ...otherProps }) => (
  <MyDatePickerContainer>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        label={label}
        value={value ? moment(value) : (values && notNull(values[id]) ? moment(values[id]) : null)}
        onChange={(date, val) => onChange(id, val)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        size="small"
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  </MyDatePickerContainer>
);

export default WrapWithContainer(MyDatePicker);
