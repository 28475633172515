import React from 'react';
import { FormControlLabel, FormControl, FormHelperText, Checkbox } from '@material-ui/core';
import { notNull } from 'utils/text';
import { MyCheckboxContainer } from './style';
import WrapWithContainer from '../CommonWrapper';

const MyCheckbox = ({ id, label = null, value, values, onChange = () => {}, className, error, helperText, ...otherProps }) => (
    <MyCheckboxContainer {...otherProps}>
      <FormControl error={error} component="fieldset" className={className}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values && notNull(values[id]) ? parseInt(values[id]) == 1 : value == 1}
              onChange={(e) => onChange(id, e.target.checked ? 1 : 0)}
              color="primary"
            />
          }
          label={label}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
      
    </MyCheckboxContainer>
  );

export default WrapWithContainer(MyCheckbox);
