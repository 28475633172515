import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { notNull } from 'utils/text';
import MaskedInput from 'react-text-mask';
import { MyInputContainer } from './style';
import WrapWithContainer from '../CommonWrapper';

const TextMask = (props) => {
  const { inputRef, mask, showMask = true, guide = false, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2013'}
      showMask={showMask}
      guide={guide}
    />
  );
}

const MyInput = ({ id, type = 'text', label = null, placeholder = '', value, values, onChange = () => {}, onFocus = () => {}, adornment, maskProps, maxLength, ...otherProps }) => (
  <MyInputContainer>
    <TextField
      id={id}
      type={type}
      label={label}
      placeholder={placeholder}
      value={notNull(value) ? value : (notNull(values) && notNull(values[id]) ? values[id] : '')}
      onChange={(e) => onChange(id, e.target.value)}
      onFocus={onFocus}
      size="small"
      variant="outlined"
      fullWidth
      InputProps={{
        ...(
          notNull(adornment) ? (
            adornment.position === 'start' ? 
            { startAdornment: (<InputAdornment position={adornment.position}>{adornment.label}</InputAdornment>) } :
            { endAdornment: (<InputAdornment position={adornment.position}>{adornment.label}</InputAdornment>) }
          ) : {}
        ),
        ...(
          notNull(maskProps) ? { inputComponent: TextMask, inputProps: maskProps } : {}
        ),
        ...(
          notNull(maxLength) ? { inputProps: { maxlength: maxLength } } : {}
        )
      }}
      {...otherProps}
    />
  </MyInputContainer>
);

export default WrapWithContainer(MyInput);
