import styled from 'styled-components';

export const MySelectContainer = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }

  .MuiTextField-root {
    .MuiFormHelperText-root.Mui-error {
      margin-left: 0;
    }
  }
`;
