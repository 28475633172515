import React from 'react';
import {
  Card,
  Divider
} from '@material-ui/core';
import { MyCardContainer, MyCardSubContainer, MyCardRowContainer } from './style';

const MyCard = ({ title, maxWidth, children }) => (
  <MyCardContainer maxWidth={maxWidth}>
    <Card className="p-4 mb-4">
      <div className="font-size-lg font-weight-bold">{title}</div>
      <Divider className="my-3" />
      <div className="card--content-wrapper">
        {children}
      </div>
    </Card>
  </MyCardContainer>
);

export default MyCard;

export const MyCardSub = ({ subTitle, maxWidth, noBorder, children, ...otherProps }) => (
  <MyCardSubContainer maxWidth={maxWidth} {...otherProps}>
    <div className="font-size-md font-weight-bold mb-1">{subTitle}</div>
    <div className="sub-card--content-wrapper">
      {children}
    </div>
    {!noBorder && <Divider className="my-3" />}
  </MyCardSubContainer>
);

export const MyCardRow = ({ responsivePoint = 576, maxWidth, direction='row', children, ...otherProps }) => (
  <MyCardRowContainer responsivePoint={responsivePoint} maxWidth={maxWidth} direction={direction} {...otherProps}>
    {children}
  </MyCardRowContainer>
);