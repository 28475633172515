/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const WrapperContainer = styled.div`
  width: 100%;
  ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
  margin: 8px 0;

  .MuiInputBase-input {
    font-size: 14px;
    line-height: 20px;
  }

  .MuiInputLabel-outlined {
    font-size: 14px;
    line-height: 20px;
  }

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 14px;
    padding-bottom: 14px;
    height: 17px;
    min-height: 0;
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  /*
  @media (max-width: 576px) {
    max-width: 100%;
  }
  */
`;