const Config = {
  PAGE_SIZE: 25,
  STATES_LIST: [
    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC',
    'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
    'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO',
    'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP',
    'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN',
    'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY', '--',
    'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE',
    'QC', 'SK', 'YT'
  ],
  VEHICLE_TYPE: [
    {
      label: 'Town Car',
      value: 0,
    },
    {
      label: 'Mini Van',
      value: 1,
    },
    {
      label: 'Sedan',
      value: 2,
    },
    {
      label: 'Stretch',
      value: 3,
    },
    {
      label: 'Van - Standard',
      value: 4,
    },
    {
      label: 'Van - Luxury',
      value: 5,
    },
    {
      label: 'Hybrid',
      value: 6,
    },
    {
      label: 'SUV',
      value: 7,
    },
    {
      label: 'Handicap',
      value: 8,
    },
    {
      label: 'Compact',
      value: 9,
    },
  ],
  MAS_VEHICLE_TYPE: [
    {
      label: 'Ambulance',
      value: 0,
    },
    {
      label: 'Livery',
      value: 1,
    },
    {
      label: 'Personal Car',
      value: 2,
    },
    {
      label: 'Specialty',
      value: 3,
    },
    {
      label: 'Stretcher',
      value: 4,
    },
    {
      label: 'Taxi',
      value: 5,
    },
    {
      label: 'Wheelchair',
      value: 6,
    }
  ],
  FARES_INCREMENT: [
    '1', '1/5', '1/4', '1/2', '3/4', '1/7', '1/8',
    '3/8', '5/8', '7/8', '1/10', '3/10', '7/10', '9/10',
    '1/12', '5/12', '7/12', '11/12', '1/16', '3/16', '5/16',
    '7/16', '9/16', '11/16', '13/16', '15/16'
  ],
  DEFAULT_FARE_MULTIPLIERS: [
    {"name":"townCar","desc":"Town Car","multiplier":"1.00","enabled":1},
    {"name":"miniVan", "desc":"Mini Van","multiplier":"1.00","enabled":1},
    {"name":"sedan", "desc":"Sedan","multiplier":"1.00","enabled":1},
    {"name":"stretch", "desc":"Stretch","multiplier":"1.00","enabled":1},
    {"name":"vanStandard", "desc":"Van - Standard","multiplier":"1.00","enabled":1},
    {"name":"vanLuxury", "desc":"Van - Luxury","multiplier":"1.00","enabled":1},
    {"name":"hybrid", "desc":"Hybrid","multiplier":"1.00","enabled":1},
    {"name":"suv", "desc":"SUV","multiplier":"1.00","enabled":1},
    {"name":"handicap", "desc":"Handicap","multiplier":"1.00","enabled":1},
    {"name":"compact", "desc":"Compact","multiplier":"1.00","enabled":1}
  ],
  RELOAD_BALANCE: [
    {
      label: '$25',
      value: 25,
    },
    {
      label: '$50',
      value: 50,
    },
    {
      label: '$75',
      value: 75,
    },
    {
      label: '$100',
      value: 100,
    },
  ],
  PHONE_MASK: ['(',/\d/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/]
};

export default Config;