import styled from 'styled-components';

export const MyButtonContainer = styled.div`
  .MuiButton-root {
    width: 100%;
    font-size: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
`;
