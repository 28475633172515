import styled from 'styled-components';

export const MyDatePickerContainer = styled.div`
  .MuiFormControl-root {
    width: 100%;
    
    .MuiFormLabel-root {
      font-size: 14px;
    }

    .MuiIconButton-root {
      padding: 5px;
    }

    .MuiFormHelperText-root.Mui-error {
      margin-left: 0;
    }
  }
`;
