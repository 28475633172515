import styled from 'styled-components';

export const MyInputContainer = styled.div`
  .MuiTextField-root {
    .MuiInputBase-root {
      input.MuiInputBase-input::-webkit-outer-spin-button,
      input.MuiInputBase-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input.MuiInputBase-input[type=number] {
        -moz-appearance:textfield;
      }
    }

    .MuiFormHelperText-root.Mui-error {
      margin-left: 0;
    }
  }
`;
