import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { notNull } from 'utils/text';
import { MySelectContainer } from './style';
import WrapWithContainer from '../CommonWrapper';

const MySelect = ({ id, label = null, placeholder = '', value, values, options, onChange = () => {}, ...otherProps }) => (
  <MySelectContainer>
    <TextField
      id={id}
      fullWidth
      select
      label={label}
      value={notNull(value) ? value : (values && notNull(values[id]) ? values[id] : '')}
      onChange={(e) => onChange(id, e.target.value)}
      variant="outlined"
      size="small"
      {...otherProps}
      >
      {options && options.length > 0 ? options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )) : <MenuItem />}

    </TextField>
  </MySelectContainer>
);

export default WrapWithContainer(MySelect);
