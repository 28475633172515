import styled from 'styled-components';

export const MyDescriptionContainer = styled.div`
  min-height: 42px;
  display: flex;
  align-items: initial;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: ${props => props.fontSize || 14}px;
    margin: 5px 0;
    line-height: 150%;
  }

  @media (max-width: 576px) {
    min-height: 0;
  }
`;
