import axios from 'axios';

const base_url = `${process.env.BACKEND_API_BASE_URL}/payment-portal-login.php`;

export const isBrowser = () => typeof window !== 'undefined';

export const getLoginUser = () =>
  isBrowser() && window.localStorage.getItem('PergoPaymentUser')
    ? JSON.parse(window.localStorage.getItem('PergoPaymentUser'))
    : {};

export const setLoginUser = user => {
  window.localStorage.setItem('PergoPaymentUser', JSON.stringify(user));
};

export const isLoggedIn = () => {
  const user = getLoginUser();
  return !!user.email;
};

export const logout = callback => {
  setLoginUser({});
};

export const loginService = {
  signup: async ({ fname, lname, email, password }) => {
    return axios.get(base_url, {
      params: {
        method: 'signup',
        fname,
        lname,
        email,
        password,
      },
    });
  },
  login: async ({ email, password }) => {
    return axios.get(base_url, {
      params: {
        method: 'login',
        email,
        password,
      },
    });
  },
  update: async ({ id, fname, lname }) => {
    return axios.get(base_url, {
      params: {
        method: 'update',
        id,
        fname,
        lname,
      },
    });
  },
  update_password: async ({ email, password }) => {
    return axios.get(base_url, {
      params: {
        method: 'update_password',
        email,
        password,
      },
    });
  },
  unlink_account: async (data) => axios.get(base_url, {
    params: {
      method: 'unlink_account',
      ...data,
      token: getLoginUser().token,
    },
  }),
  unlink_stripe_account: async (data) => axios.get(base_url, {
    params: {
      method: 'unlink_stripe_account',
      ...data,
      token: getLoginUser().token,
    },
  }),
};
